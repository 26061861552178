import { createReducer, on } from '@ngrx/store';
import {
  convertConsultationFlow,
  createCurrentConsultation,
  createCurrentConsultationFail,
  createCurrentConsultationSuccess,
  placeVetSourceOrderError,
  placeVetSourceOrderSuccess,
  resetConsultationData,
  resetConsultationFlow,
  resetCurrentConsultation,
  resetExpectedNextVisit,
  setConsultationAllowanceParams,
  setConsultationLastConsultation,
  setConsultationPatient,
  setConsultationPetOwner,
  setConsultationRenalDetectNutritionalChoice,
  setConsultationRenalDetectRecommendation,
  setConsultationSetSkipProductBook,
  setCreatingCurrentConsultation,
  setCurrentConsultationVisitDate,
  setExpectedNextVisit,
  updatePetOwnerSuccess,
} from './consultation.actions';
import { initialConsultationState } from './consultation.state';

/**
 * Reducer for all data shared between tools during a consultation
 */
export const consultationReducer = createReducer(
  initialConsultationState,
  on(setConsultationAllowanceParams, (state, action) => ({
    ...state,
    allowanceParams: action.value,
  })),
  on(setConsultationPatient, (state, action) => ({
    ...state,
    patient: action.value,
  })),
  on(setConsultationPetOwner, (state, action) => ({
    ...state,
    petOwner: action.value,
  })),
  on(updatePetOwnerSuccess, (state, action) => ({
    ...state,
    petOwner: action.value,
  })),
  on(setConsultationRenalDetectRecommendation, (state, action) => ({
    ...state,
    renalDetectRecommendation: action.value,
  })),
  on(setConsultationRenalDetectNutritionalChoice, (state, action) => ({
    ...state,
    renalDetectNutritionalChoice: action.value,
  })),
  on(setConsultationLastConsultation, (state, action) => ({
    ...state,
    lastConsultation: action.value,
  })),
  on(createCurrentConsultation, (state) => ({
    ...state,
    creatingCurrentConsultation: true,
  })),
  on(createCurrentConsultationSuccess, (state, action) => ({
    ...state,
    currentConsultation: action.consultation,
    creatingCurrentConsultation: false,
  })),
  on(resetCurrentConsultation, (state) => ({
    ...state,
    currentConsultation: null,
  })),
  on(createCurrentConsultationFail, (state) => ({
    ...state,
    creatingCurrentConsultation: false,
  })),
  on(setCreatingCurrentConsultation, (state, action) => ({
    ...state,
    creatingCurrentConsultation: action.value,
  })),
  on(setCurrentConsultationVisitDate, (state, action) => ({
    ...state,
    currentConsultationVisitDate: action.value,
  })),
  on(setConsultationSetSkipProductBook, (state, action) => ({
    ...state,
    skipProductBook: action.value,
  })),
  on(convertConsultationFlow, (state, action) => ({
    ...state,
    allowanceParams: { ...state.allowanceParams, tool: action.flow },
  })),
  on(resetConsultationFlow, (state, action) => ({
    ...state,
    allowanceParams: { ...state.allowanceParams, tool: action.coreTool },
  })),
  on(setExpectedNextVisit, (state, action) => ({
    ...state,
    expectedNextVisit: action.value,
  })),
  on(resetExpectedNextVisit, (state) => ({
    ...state,
    expectedNextVisit: null,
  })),
  on(resetConsultationData, () => initialConsultationState),
  on(placeVetSourceOrderSuccess, (state) => ({ ...state, vetSourceOrderStatus: 'SUCCESS' as const })),
  on(placeVetSourceOrderError, (state) => ({ ...state, vetSourceOrderStatus: 'ERROR' as const }))
);
