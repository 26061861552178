import { EnumsService } from '@app/core/services';
import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { CoreFacade } from '@app/store/core/core.facade';
import { VetFacade } from '@app/store/vet';
import { tap, switchMap, filter } from 'rxjs/operators';

@Component({
  selector: 'app-main-page',
  templateUrl: './srs-main-page.component.html',
  styleUrls: ['./srs-main-page.component.scss'],
})
export class SrsMainPageComponent implements OnInit {
  isConfigValid$ = this.vetFacade.isVetValid$.pipe(
    tap((isVetValid) => !isVetValid && this.vetFacade.loadVet()),
    tap((isConfigValid) => this.coreFacade.toggleLoader(!isConfigValid)),
    filter((isConfigValid) => !!isConfigValid),
    switchMap(() => {
      // TODO: once flows are all migrated, only use store for breeds
      return this.enumsService.fetchBreeds();
    })
  );

  constructor(
    private vetFacade: VetFacade,
    private coreFacade: CoreFacade,
    private enumsService: EnumsService,
    private title: Title,
    @Inject(LOCALE_ID) protected localeId: string
  ) {}

  ngOnInit(): void {
    this.title.setTitle('Smart Reco');
    this.coreFacade.toggleLoader(true);
  }
}
