import { Component, OnInit } from '@angular/core';
import { startSmartReco } from '@app/pages/smart-reco/store/actions/smart-reco-steps.actions';
import { Helper, Tool } from '@app/shared/utils';
import { AppState } from '@app/store';
import { setTool } from '@app/store/core';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-srs-init',
  template: '',
})
export class SrsInitComponent implements OnInit {
  constructor(private store$: Store<AppState>) {}

  ngOnInit(): void {
    this.store$.dispatch(startSmartReco({ withIdentification: false, isNewPet: true }));
    this.store$.dispatch(setTool({ tool: Tool.SmartReco, tool_flow_id: Helper.randomId() }));
  }
}
