import { CommonModule, DatePipe } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS, provideHttpClient, withInterceptors, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { RouterModule } from '@angular/router';
import {
  ActionCommunicationService,
  ApiManagerService,
  ApiService,
  AuthModule,
  BlobService,
  ChartService,
  DomService,
  EmailService,
  EnumsService,
  LoaderService,
  Logger,
  ModalService,
  NutritionService,
  ObservablesLayerService,
  OrderService,
  PetCommunicationService,
  ProductService,
  RenalDetectService,
  ScriptService,
  TokenInterceptor,
  UploadService,
  VetService,
  WindowService,
  POLetterService,
} from '@app/core/services';
import { SharedModule } from '@app/shared';
import { ArianeTranslationPipe } from '@app/shared/pipes';
import { DeprecatedLoaderComponent, HeaderComponent, OverlayComponent } from './components';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { StartFlowService } from './services/start-flow/start-flow.service';
import { AnalyticsInterceptor } from './services/tracking/services/analytics.interceptor';
import { GtmEventsService } from './services/tracking/services/gtm-events.service';
import { GTMService } from './services/tracking/services/gtm.service';
import { UserSessionAnalyticsInterceptor } from './services/tracking';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  imports: [CommonModule, RouterModule, HttpClientModule, AuthModule, MatDialogModule, SharedModule.forRoot()],
  declarations: [OverlayComponent, DeprecatedLoaderComponent, ProgressBarComponent, HeaderComponent],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AnalyticsInterceptor,
      multi: true,
    },
    provideHttpClient(withInterceptors([UserSessionAnalyticsInterceptor]), withInterceptorsFromDi()),
    WindowService,
    ChartService,
    DomService,
    ModalService,
    LoaderService,
    ScriptService,
    GTMService,
    PetCommunicationService,
    StartFlowService,
    /* Network Services */
    ApiService,
    ApiManagerService,
    ObservablesLayerService,
    ProductService,
    VetService,
    NutritionService,
    EnumsService,
    EmailService,
    ActionCommunicationService,
    UploadService,
    GtmEventsService,
    OrderService,
    Logger,
    BlobService,
    POLetterService,
    RenalDetectService,
    /* Pipes */
    DatePipe,
    ArianeTranslationPipe,
  ],
  exports: [OverlayComponent, DeprecatedLoaderComponent, ProgressBarComponent, HeaderComponent],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Important only in AppModule');
    }
  }
}
