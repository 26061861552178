import { AVAILABLE_LANGUAGES } from '@app/core/services/utils/localization/localization';
import { LocalizationService } from '@app/core/services/utils/localization/localization.service';

/**
 * Credentials
 */
const phraseAccessToken = '436b4093e476cc95bcdf0cb09c1b174e6328f6515e6ff7f42dc043ddfd79b8a9';
const phraseProjectId = '4d8906558ee909282c6da4f59dd78f4e';
/**
 * Define init language
 */
const defineInitLanguage = () => {
  const language = LocalizationService.completeLanguageCode(LocalizationService.initLanguage || navigator.language);
  LocalizationService.initLanguage = language;
  return language;
};
/**
 * Generate translations
 * Parse to JSON
 * Clean nullish values
 * Copy key as value for demo env
 */
const generateTranslations = (textTranslations: string) => {
  let translations = JSON.parse(textTranslations);
  for (const [key, value] of Object.entries(translations)) {
    if (!value) {
      translations[key] = '';
    }
  }
  if (LocalizationService.isPhraseSdkEnabled) {
    const demoTranslations: Record<string, string> = {};
    for (const key of Object.keys(translations)) {
      demoTranslations[key] = `[[__phrase_${key}__]]`;
    }
    translations = demoTranslations;
  }
  return translations;
};
/**
 * Load locale from Phrase
 */
const loadLocale = (localeId: string): Promise<Record<string, string>> => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    const handleError = (message: string) => () => {
      reject(new Error(message));
    };

    xhr.onload = () => {
      try {
        const translations = generateTranslations(xhr.responseText);
        resolve(translations);
      } catch (e) {
        reject(e);
      }
    };
    xhr.onerror = handleError('Phraseapp request failed');
    xhr.ontimeout = handleError('Phraseapp request timed out');
    xhr.onabort = handleError('Phraseapp request aborted');
    xhr.onreadystatechange = () => {
      if (xhr.status >= 400) {
        reject('Phraseapp error');
      }
    };

    const url = `https://api.phrase.com/v2/projects/${phraseProjectId}/locales/${localeId}/download?file_format=simple_json&include_empty_translations=true&fallback_locale_id=1e152e8a9188aed43d9f1b364a6044d5&access_token=${phraseAccessToken}`;
    xhr.open('GET', url, true);
    xhr.send();
  });
};
/**
 * Get phrase locale id from language code
 */
const getPhraseLocaleId = (languageCode: string) => {
  return AVAILABLE_LANGUAGES.find((l) => l.code === languageCode).phraseId;
};

/**
 * Check if query param to turn on or off phrase sdk is present
 * This must be run before app init
 */
const setIsPhraseSdkEnabled = (): void => {
  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has('phrase')) {
    LocalizationService.isPhraseSdkEnabled = urlParams.get('phrase') === 'on' ? true : false;
  }
};
/**
 * Method to define init language and set angular locale data
 * Required in polyfills
 */
export const initLocalization = (): void => {
  setIsPhraseSdkEnabled();
  defineInitLanguage();
};
/**
 * Method to get the locale and associated translations
 */
export const getLocalizationData = async (): Promise<{
  locale: string;
  translations: Record<string, string>;
}> => {
  const locale = LocalizationService.initLanguage;
  const phraseId = getPhraseLocaleId(locale);
  const translations = await loadLocale(phraseId);
  return { locale, translations };
};
