import { Injectable } from '@angular/core';
import { VetService } from '@app/core/services';
import { AppState } from '@app/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { RcToasterService } from '@rc/ui';
import {
  checkForExistingOwner,
  petOwnerFail,
  placeVetSourceOrder,
  placeVetSourceOrderError,
  placeVetSourceOrderSuccess,
} from '../consultation.actions';
import { catchError, map, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { Clinic, Consultation, PetInfo, PetOwner } from '@app/core/models';
import { combineLatest, EMPTY, Observable } from 'rxjs';
import { CreatePOPopinComponent, CreatePOPopinData } from '@app/shared/components';
import { translateKey } from '@app/shared/utils/static-helpers/translate';
import { GTMService, mapToolToModule, ShareRecommendationButtonActionGtm, shareRecoTracking } from '@app/core/services/tracking';
import { VetFacade } from '@app/store/vet';
import { ShareRecommendationFacade } from '@app/store/share-recommendation';
import { MatDialog } from '@angular/material/dialog';
import { DialogHelpers } from '@app/shared/utils/static-helpers/dialog-helpers';

@Injectable()
export class ConsultationVetSourceEffect {
  public checkForExistingOwner$ = createEffect(() =>
    this.actions$.pipe(
      ofType(checkForExistingOwner),
      switchMap(({ email, owner, clinic, currentConsultation, petInfo }) => {
        if (!owner) {
          return this.dialog
            .open<CreatePOPopinComponent, CreatePOPopinData>(CreatePOPopinComponent, {
              ...DialogHelpers.defaultConfig(),
              data: {
                email,
                country: clinic.companyAddress?.country,
              },
            })
            .afterClosed()
            .pipe(
              switchMap((res) => {
                if (res) {
                  return this.createPetOwner(res?.values).pipe(
                    map(() => placeVetSourceOrder({ email, clinic, currentConsultation, petInfo }))
                  );
                }
                return EMPTY;
              })
            );
        } else {
          return [placeVetSourceOrder({ email, clinic, petInfo, currentConsultation })];
        }
      })
    )
  );

  public placeVetSourceOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(placeVetSourceOrder),
      switchMap(({ clinic, currentConsultation, email, petInfo }) => {
        return this.placeVetSourceOrder(email, clinic, petInfo, currentConsultation).pipe(
          mergeMap(() => this.shareRecommendationTracking()),
          map(() => placeVetSourceOrderSuccess()),
          catchError((error: string) => {
            this.store$.dispatch(placeVetSourceOrderError({ error }));
            return EMPTY;
          })
        );
      })
    )
  );

  public placeVetSourceOrderSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(placeVetSourceOrderSuccess),
        tap(() => {
          return this.toaster.open({
            text: translateKey(`request_vet_source_sent`),
            type: 'success',
          });
        })
      ),
    { dispatch: false }
  );

  public placeVetSourceOrderError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(placeVetSourceOrderError),
        tap(({ error }) => {
          return this.toaster.open({
            title: translateKey(`error_general_title:`),
            text: error,
            type: 'error',
          });
        })
      ),
    { dispatch: false }
  );

  private currentLanguage$ = this.vetFacade.language$;
  private trackingCode$ = this.vetFacade.conversionConfiguration$.pipe(
    map((conversionConfiguration) => conversionConfiguration?.trackingCode)
  );
  private currentTool$ = this.shareRecommendationFacade.selectShareRecommendationData$.pipe(
    map((shareRecommendationData) => shareRecommendationData.tool)
  );

  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private vetService: VetService,
    private toaster: RcToasterService,
    private dialog: MatDialog,
    private vetFacade: VetFacade,
    private shareRecommendationFacade: ShareRecommendationFacade,
    private trackingService: GTMService
  ) {}

  private placeVetSourceOrder(
    petOwnerEmail: string,
    clinic: Clinic,
    petInfo: PetInfo,
    currentConsultation: Consultation
  ): Observable<void> {
    return this.vetService.sendVetsourcesConsultation(petOwnerEmail, clinic, petInfo, currentConsultation);
  }

  private createPetOwner(petOwnerInfo: PetOwner): Observable<PetOwner> {
    return this.vetService.createOwner(petOwnerInfo).pipe(
      catchError((error) => {
        this.store$.dispatch(petOwnerFail({ error }));
        return EMPTY;
      })
    );
  }

  private shareRecommendationTracking(): Observable<unknown> {
    return combineLatest([this.trackingCode$, this.currentLanguage$, this.currentTool$]).pipe(
      take(1),
      tap(([trackingCode, language, tool]) => {
        this.trackingService.sendInteraction(
          shareRecoTracking(ShareRecommendationButtonActionGtm.SEND_ORDER, mapToolToModule(tool), trackingCode, language)
        );
      })
    );
  }
}
