import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule } from '@app/core';
import { SharedModule } from '@app/shared';
import { ArianeTranslationPipe } from '@app/shared/pipes';
import { AppStoreModule } from '@app/store/app-store.module';
import { environment } from '@env/environment';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { OKTA_CONFIG, OktaAuthModule } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { AppRoutingModule, routingComponents } from './app-routing.module';
import { AppComponent } from './app.component';
import { LocalizationService } from './core/services/utils/localization/localization.service';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import {
  zhCN as zh,
  zhHK,
  zhTW,
  cs,
  da,
  nlBE,
  nl,
  enAU,
  enGB,
  enCA,
  enUS,
  et,
  fi,
  fr,
  frCA,
  fr as frBE,
  de,
  el,
  hu,
  it,
  ko,
  lv,
  lt,
  nb,
  pl,
  pt,
  ro,
  ru,
  sk,
  es as esAR,
  es,
  es as esMX,
  sv,
  tr,
} from 'date-fns/locale';

import { LoaderModule } from './core/components/loader/loader.module';
import { ErrorHandlerService } from './core/services';

/**
 * Change pkce to false for IE in order not to crash the app
 * and display deprecation page
 */
function getOktaConfig() {
  const isIE = navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > -1;
  return {
    ...environment.oktaConfig,
    pkce: !isIE,
  };
}

const locales = {
  zh,
  zhHK,
  zhTW,
  cs,
  da,
  nlBE,
  nl,
  enAU,
  enGB,
  enCA,
  enUS,
  et,
  fi,
  fr,
  frCA,
  frBE,
  de,
  el,
  hu,
  it,
  ko,
  lv,
  lt,
  nb,
  pl,
  pt,
  ro,
  ru,
  sk,
  esAR,
  es,
  esMX,
  sv,
  tr,
};

const oktaAuth = new OktaAuth(getOktaConfig());
@NgModule({
  declarations: [AppComponent, ...routingComponents],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CoreModule,
    AppStoreModule,
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
      connectInZone: true,
    }),
    OktaAuthModule,
    SharedModule,
    LoaderModule,
  ],
  providers: [
    /* Pipes */
    ArianeTranslationPipe,
    { provide: OKTA_CONFIG, useValue: { oktaAuth } },
    { provide: LOCALE_ID, useValue: LocalizationService.initLanguage },
    { provide: ErrorHandler, useClass: ErrorHandlerService },
    {
      provide: MAT_DATE_LOCALE,
      useValue:
        locales[LocalizationService.initLanguage.split('-').join('')] ||
        locales[LocalizationService.initLanguage.split('-')[0]] ||
        locales.enGB,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
