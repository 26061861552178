import { AsyncActionStatus, NutritionalChoice } from '@app/shared/utils';
import { PetOwner } from '@app/core/models/pet-owner';
import { Consultation, Patient, Product, RenalDetectRecommendation } from '@app/core/models';
import { AllowanceParams } from '@app/pages/allowance/allowance';
import { NextVisitData } from '@app/core/models/visit-next';

/**
 * State for all data shared between tools during a consultation
 */
export interface ConsultationState {
  patient: Patient | null;
  petOwner: PetOwner | null;
  renalDetectRecommendation: RenalDetectRecommendation | null;
  renalDetectNutritionalChoice: NutritionalChoice | null;
  lastConsultation: Consultation | null;
  creatingCurrentConsultation: boolean;
  currentConsultation: Consultation | null;
  selectedDryProduct: Product | null;
  selectedDryPackId: string | null;
  selectedWetProduct: Product | null;
  selectedWetPackId: string | null;
  allowanceParams: AllowanceParams | null;
  currentConsultationVisitDate: Date | null;
  skipProductBook: boolean;
  expectedNextVisit: NextVisitData | null;
  vetSourceOrderStatus: AsyncActionStatus;
}

export const initialConsultationState: ConsultationState = {
  patient: null,
  petOwner: null,
  renalDetectRecommendation: null,
  renalDetectNutritionalChoice: null,
  lastConsultation: null,
  creatingCurrentConsultation: false,
  currentConsultation: null,
  selectedDryProduct: null,
  selectedDryPackId: null,
  selectedWetProduct: null,
  selectedWetPackId: null,
  allowanceParams: null,
  currentConsultationVisitDate: null,
  skipProductBook: false,
  expectedNextVisit: null,
  vetSourceOrderStatus: 'NONE',
};
