import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { BrowserDeprecationComponent, MainPageComponent } from '@app/pages';
import { OktaCallbackComponent } from '@app/pages/okta/callback.component';
import { PetPageComponent } from '@app/pages/pet-page/container/pet-page.component';
import { StandaloneGuard } from './standalone/standalone-guard';
import { SrsInitComponent } from './standalone/srs-init.component';
import { SrsMainPageComponent } from './standalone/srs-main-page/srs-main-page.component';
import { SrsNavComponent } from './standalone/standalone-nav/srs-nav.component';
import { StandaloneAccessDeniedComponent } from './standalone/standalone-access-denied/standalone-access-denied.component';
import { StandaloneProgressBarComponent } from './standalone/standalone-progress-bar/standalone-progress-bar.component';
import { AppRoutes } from './shared/utils/enums/routes.enum';

const routes: Routes = [
  {
    path: AppRoutes.CALLBACK,
    component: OktaCallbackComponent,
  },
  {
    path: AppRoutes.BROWSER_DEPRECATED,
    component: BrowserDeprecationComponent,
  },
  {
    path: AppRoutes.ACCESS_DENIED,
    component: StandaloneAccessDeniedComponent,
  },
  {
    path: '',
    component: SrsMainPageComponent,
    canActivate: [StandaloneGuard],
    runGuardsAndResolvers: 'always',
    children: [
      {
        path: '',
        component: SrsInitComponent,
      },
      {
        path: AppRoutes.SMART_RECO,
        loadChildren: () => import('app/pages/smart-reco/smart-reco.module').then((m) => m.SmartRecoModule),
      },
      {
        path: AppRoutes.ALLOWANCE,
        loadChildren: () => import('app/pages/allowance/allowance.module').then((m) => m.AllowanceModule),
      },
      {
        path: AppRoutes.SHARE_RECOMMENDATION,
        loadComponent: () =>
          import('app/pages/share-recommendation/share-recommendation.component').then((c) => c.ShareRecommendationComponent),
      },
    ],
  },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
  declarations: [],
  providers: [StandaloneGuard],
})
export class AppRoutingModule {}
/**
 * These components are imported directly into AppModule for retrocompatibility
 */
export const routingComponents: any[] = [
  BrowserDeprecationComponent,
  OktaCallbackComponent,
  /**
   * Srs component
   */
  SrsInitComponent,
  SrsMainPageComponent,
  StandaloneProgressBarComponent,
  SrsNavComponent,
  StandaloneAccessDeniedComponent,
  /**
   * Needs to be imported because PetCommunicationService
   */
  PetPageComponent,
  MainPageComponent,
];
