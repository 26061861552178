import { Injectable } from '@angular/core';
import { Consultation, Patient, Pet, PetOwner, Weight } from '@app/core/models';
import { NutritionService, PetService, VetService } from '@app/core/services';
import { CreatePatientPopinComponent, CreatePatientPopinData } from '@app/shared/components';
import { Helper, MeasurementCodeType, Tool } from '@app/shared/utils';
import { buildConsultationApiBody } from '@app/shared/utils/static-helpers/consultation-helper';
import { formatOwnerFormValuesToOwner } from '@app/shared/utils/static-helpers/patient-helper';
import { AppState } from '@app/store';
import { setAlert } from '@app/store/core';
import { selectCurrentClinicCountry, selectCurrentClinicId } from '@app/store/vet';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { RCAlertType } from '@rc/ui';
import { EMPTY, Observable } from 'rxjs';
import { catchError, concatMap, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import {
  createCurrentConsultation,
  createCurrentConsultationFail,
  createCurrentConsultationSuccess,
  getExpectedNextVisit,
  resetExpectedNextVisit,
  setConsultationPatient,
  setCreatingCurrentConsultation,
  setExpectedNextVisit,
  updateConsultationNextVisit,
  updateConsultationNextVisitSuccess,
} from '../consultation.actions';
import { selectConsultationLastConsultation, selectConsultationPatient, selectExpectedNextVisit } from '../consultation.selectors';
import { PetInfo } from './../../../core/models/pet-info';
import { selectAllowanceCurrentProgram } from '@app/pages/allowance/store/selectors/allowance-program.selectors';
import { selectGrowthChartData } from '@app/store/pet';
import { isNullOrUndefined } from '@app/shared';
import { MatDialog } from '@angular/material/dialog';
import { DialogHelpers } from '@app/shared/utils/static-helpers/dialog-helpers';

@Injectable()
export class ConsultationCreateEffects {
  /**
   * Create a new consultation
   * if needed, create a new patient or update existing one
   */
  createConsultation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createCurrentConsultation),
      concatLatestFrom(() => [
        this.store$.select(selectConsultationPatient),
        this.store$.select(selectCurrentClinicId),
        this.vetService.currentBigMeasurementUnit$,
        this.store$.select(selectCurrentClinicCountry),
      ]),
      switchMap(
        ([
          { consultationData, petInfo, tool, createPatientPopinTitle, owner, isRenewal = false },
          patient,
          clinicId,
          currentBigMeasurementUnit,
          country,
        ]) => {
          let firstStep: Observable<{ patient: Patient }> | null = null;
          const newPet = isNullOrUndefined(patient);
          /**
           * First we need a valid patient,
           * if it exists, we only update the pet and create the consultation
           */
          if (patient) {
            firstStep = this.updatePetAndCreateConsultation(patient, petInfo, consultationData, isRenewal, currentBigMeasurementUnit);
            /**
             * If we don't have the patient but a valid owner,
             * we need to create the patient and the consultation
             */
          } else if (owner) {
            firstStep = this.createPatientAndConsultation(petInfo, owner, clinicId, consultationData, isRenewal, currentBigMeasurementUnit);
            /**
             * If we don't have a valid patient nor a valid owner,
             * we need to create the patient, the owner and the consultation through the create-patient-popin
             */
          } else {
            firstStep = this.createPatientAndOwnerAndConsultation(
              petInfo,
              createPatientPopinTitle,
              tool,
              clinicId,
              consultationData,
              isRenewal,
              country
            );
          }
          return firstStep.pipe(
            /**
             * We now have a valid & up to date patient and consultation, we store the consultation and the patient in the store
             */
            switchMap(({ patient: updatedPatient }) => [
              setConsultationPatient({ value: updatedPatient }),
              createCurrentConsultationSuccess({ consultation: updatedPatient.consultation, newPet }),
            ])
          );
        }
      )
    )
  );

  /**
   * PUT next visit after create Consultation Success
   */
  putNextVisitAfterCreateConsultationSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createCurrentConsultationSuccess),
      concatLatestFrom(() => [this.store$.select(selectExpectedNextVisit)]),
      filter(([_, nextVisit]) => !!nextVisit),
      switchMap(([{ consultation }, nextVisit]) => [
        updateConsultationNextVisit({
          nextVisit: { expected: { visitDateTime: nextVisit.intermediateVisit.weightDate?.toISOString() } },
          patientId: consultation.patientId,
          consultationId: consultation.id,
        }),
      ])
    )
  );

  /**
   * PUT weights (past weights added on growth chart)
   */
  putWeightsAfterCreateConsultationSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(createCurrentConsultationSuccess),
        concatLatestFrom(() => [this.store$.select(selectGrowthChartData).pipe(map((data) => data.notSavedPastWeights))]),
        filter(([_, notSavedPastWeights]) => !!notSavedPastWeights?.length),
        switchMap(([{ consultation }, notSavedPastWeights]) => {
          // TODO - TTO: backend will be improved so we do not have to do a foreach but rather a single request
          return [
            notSavedPastWeights.forEach((weight: Weight) => {
              const { id: _, ...rest } = weight;
              return this.addWeightToPet(consultation.nextVisit.petId, rest).subscribe();
            }),
          ];
        })
      ),
    { dispatch: false }
  );

  /**
   * DELETE current weight for existing pet
   * workaround not meant to live forever
   * if this is still here in 6 months, raise an alert
   */

  deleteCurrentWeightForExistingPetAfterCreateConsultationsuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(createCurrentConsultationSuccess),
        concatLatestFrom(() => [this.store$.select(selectConsultationPatient)]),
        filter(([{ newPet }]) => !newPet),
        switchMap(([, patient]) => {
          return this.petService.getPetWeights(patient.pet.id);
        }),
        map((weights) => {
          const sortedWeights = [...weights].sort((a, b) => new Date(a.weightDate).getTime() - new Date(b.weightDate).getTime());
          const weight = sortedWeights[sortedWeights.length - 2]; // the penultimate (avant-dernier for les francophones) weight is the correct one
          return { id: weight.id, petId: weight.petId };
        }),
        switchMap((body) => this.petService.deletePetWeight(body))
      ),
    { dispatch: false }
  );

  /**
   * Create consultation fail
   */
  createConsultationFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createCurrentConsultationFail),
      switchMap(() => [
        setAlert({
          alert: {
            message: $localize`:@@error_general_text:`,
            alertType: RCAlertType.ERROR,
          },
        }),
        setCreatingCurrentConsultation({ value: false }),
      ])
    )
  );

  /**
   * Get next visit request
   */

  getExpectedNextVisit$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getExpectedNextVisit),
      concatLatestFrom(() => [
        this.store$.select(selectConsultationLastConsultation),
        this.store$.select(selectAllowanceCurrentProgram),
        this.vetService.currentBigMeasurementUnit$,
        this.vetService.currentSystemPreferenceCode$,
      ]),
      switchMap(([action, lastConsultation, program, currentBigMeasurementUnit, currentSystemPreferenceCode]) => {
        const firstDate = lastConsultation?.visit?.firstVisitDate;

        return this.nutritionService
          .planNextVisit(action.value, firstDate, program, currentBigMeasurementUnit, currentSystemPreferenceCode, action.nextDate)
          .pipe(
            map((nextVisitData) => {
              return setExpectedNextVisit({ value: nextVisitData });
            })
          );
      })
    );
  });

  /**
   * Update consultation next visit request
   */

  updateConsultationNextVisit$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(updateConsultationNextVisit),
      switchMap((action) => {
        return this.vetService.updateConsultationVisits({ nextVisit: action.nextVisit }, action.patientId, action.consultationId).pipe(
          map(() => updateConsultationNextVisitSuccess(), resetExpectedNextVisit()),
          catchError(() => {
            setAlert({
              alert: {
                message: $localize`:@@error_general_text:`,
                alertType: RCAlertType.ERROR,
              },
            });
            resetExpectedNextVisit();
            return EMPTY;
          })
        );
      })
    );
  });

  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private dialog: MatDialog,
    private vetService: VetService,
    private nutritionService: NutritionService,
    private petService: PetService
  ) {}

  private createPatientAndOwnerAndConsultation(
    petInfo: PetInfo,
    createPatientPopinTitle: string,
    tool: Tool,
    clinicId: string,
    consultationData: Partial<Consultation>,
    isRenewal: boolean,
    country: string
  ): Observable<{ patient: Patient }> {
    return (
      this.dialog
        .open<CreatePatientPopinComponent, CreatePatientPopinData>(CreatePatientPopinComponent, {
          ...DialogHelpers.defaultConfig(),
          panelClass: 'medium',
          data: {
            petInfo,
            title: createPatientPopinTitle,
            tool,
            country,
          },
        })
        .afterClosed()
        /**
         * Once the popin is closed :
         */
        .pipe(
          // TODO - TTO: we should not dispatch any action in a tap. Not a good practice anymore
          tap((data) => !data && this.store$.dispatch(setCreatingCurrentConsultation({ value: false }))),
          filter((data) => !!data),
          withLatestFrom(this.vetService.currentBigMeasurementUnit$),
          /**
           * we create a new patient with the consultation (with existing owner or not)
           */
          switchMap(([{ values }, currentBigMeasurementUnit]) =>
            this.createPatientAndConsultation(
              petInfo,
              formatOwnerFormValuesToOwner(values),
              clinicId,
              consultationData,
              isRenewal,
              currentBigMeasurementUnit
            )
          )
        )
    );
  }

  private createPatientAndConsultation(
    petInfo: PetInfo,
    owner: PetOwner,
    clinicId: string,
    consultationData: Partial<Consultation>,
    isRenewal: boolean,
    currentBigMeasurementUnit: MeasurementCodeType
  ): Observable<{ patient: Patient }> {
    return this.vetService
      .createPatient(
        Helper.petInfoToPet(petInfo, currentBigMeasurementUnit),
        { ...owner, organizationId: clinicId },
        buildConsultationApiBody(consultationData, isRenewal)
      )
      .pipe(
        map((newPatient) => ({ patient: newPatient })),
        catchError((error) => {
          this.store$.dispatch(createCurrentConsultationFail({ error }));
          return EMPTY;
        })
      );
  }

  private updatePetAndCreateConsultation(
    patient: Patient,
    petInfo: PetInfo,
    consultationData: Partial<Consultation>,
    isRenewal: boolean,
    currentBigMeasurementUnit: MeasurementCodeType
  ): Observable<{ patient: Patient }> {
    return this.vetService
      .updatePet(patient.petId, {
        breedCode: petInfo.breed,
        genderCode: petInfo.gender,
        neutered: petInfo.neutered,
        petActivityCode: petInfo.petActivity,
        reproductionStatusCode: petInfo.reproductionStatus,
        idealBodyWeight: {
          weightDate: new Date(),
          bcs: petInfo.bcs,
          measure: petInfo.IBW,
          measureUnit: currentBigMeasurementUnit,
        },
      })
      .pipe(
        /**
         * We can now update the pet inside the patient & return it
         */
        map((pet: Pet) => ({
          patient: {
            ...patient,
            pet,
          },
        })),
        concatMap(({ patient: updatedPatient }) =>
          this.vetService.createConsultation(updatedPatient, buildConsultationApiBody(consultationData, isRenewal)).pipe(
            map((consultation) => ({ patient: { ...updatedPatient, consultation } })),
            catchError((error) => {
              this.store$.dispatch(createCurrentConsultationFail({ error }));
              return EMPTY;
            })
          )
        )
      );
  }

  private addWeightToPet(petId: string, weight: Weight): Observable<Pet> {
    return this.petService.addPetWeight(petId, weight);
  }
}
