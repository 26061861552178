import { Location } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { SmartRecoStep } from '@app/pages/smart-reco/smart-reco-step';
import { setSmartRecoStep } from '@app/pages/smart-reco/store/actions/smart-reco-steps.actions';
import { selectSmartRecoStep, selectSmartRecoSteps } from '@app/pages/smart-reco/store/selectors/smart-reco-steps.selectors';
import { AppState } from '@app/store';
import { Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';

enum SrsPath {
  patient = '/smart-reco/patient',
  recommendation = '/smart-reco/recommendation',
  allowance = '/allowance',
}
interface SrsPage {
  path: SrsPath;
  key: string;
  index: number;
  steps: SmartRecoStep[];
}

@Component({
  selector: 'app-srs-nav',
  templateUrl: './standalone-nav.component.html',
  styleUrls: ['./standalone-nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SrsNavComponent {
  /**
   * Srs pages
   */
  pages: SrsPage[] = [
    {
      path: SrsPath.patient,
      key: 'path_patient',
      index: 1,
      steps: [SmartRecoStep.Profile, SmartRecoStep.Weight, SmartRecoStep.Pathologies],
    },
    {
      path: SrsPath.recommendation,
      key: 'path_products-recommendation',
      index: 2,
      steps: [SmartRecoStep.Recommendation],
    },
    { path: SrsPath.allowance, key: 'path_daily-allowance', index: 3, steps: [SmartRecoStep.Allowance] },
  ];
  /**
   * Step number to display
   */
  standalonePage$ = this.router.events.pipe(
    filter((e: Event): e is NavigationEnd => e instanceof NavigationEnd),
    map((e: NavigationEnd) => this.getSmartRecoPage(e.urlAfterRedirects)),
    startWith(this.getSmartRecoPage(this.location.path()))
  );
  /**
   * Progress bar percentage value
   */
  progressValue$ = combineLatest([this.store$.select(selectSmartRecoSteps), this.store$.select(selectSmartRecoStep)]).pipe(
    map(([steps, currentStep]) => (steps.indexOf(currentStep) + 1) * 20)
  );

  constructor(private store$: Store<AppState>, private router: Router, private location: Location) {}
  /**
   * Go back to previous step
   */
  goToStep(pageIndex: number): void {
    const page = this.pages.find((s) => s.index === pageIndex);
    this.store$.dispatch(setSmartRecoStep({ value: page.steps[0] }));
  }
  /**
   * Get page number from url
   */
  private getSmartRecoPage(path: string): SrsPage | null {
    if (path.includes(SrsPath.allowance)) {
      return this.pages[2];
    } else if (path.includes(SrsPath.recommendation)) {
      return this.pages[1];
    } else if (path.includes(SrsPath.patient)) {
      return this.pages[0];
    }
    return null;
  }
}
