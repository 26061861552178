import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from '@app/app.module';
import { environment } from '@env/environment';
import { loadTranslations } from '@angular/localize';
import { getLocalizationData } from 'locale-loader';
import 'zone.js';
import { TranslateHelper } from '@app/shared/utils/static-helpers/translate';

if (environment.production) {
  enableProdMode();
}

getLocalizationData()
  .then((data) => {
    TranslateHelper.saveTranslation(data);
    loadTranslations(data.translations);
    TranslateHelper.saveTranslationBackup(data);
  })
  .catch((e) => {
    loadTranslations(TranslateHelper.getTranslationBackup().translations);
    console.error(e);
  })
  .finally(() => {
    platformBrowserDynamic()
      .bootstrapModule(AppModule)
      .catch((err) => console.error(err));
  });
