import { ActionReducerMap } from '@ngrx/store';
import { routerReducer, RouterReducerState } from '@ngrx/router-store';

import { CoreEffects, coreReducer, CoreState, initialCoreState } from './core';
import { initialVetState, VetEffects, VetFacade, legacyVetReducer, VetState } from '@app/store/vet';
import { PetState, initialPetState, petReducer, PetEffects } from '@app/store/pet';
import { PatientState, initialPatientState, patientReducer, PatientEffects, PatientFacade } from '@app/store/patient';
import { consultationReducer, ConsultationState, initialConsultationState } from './consultation';
import { ConsultationEffects } from './consultation/effects';
import { initialProductsState, ProductsEffects, productsReducer, ProductsState } from './products';
import { ProductsFacade } from '@app/store/products/products.facade';
import { ConsultationFacade } from '@app/store/consultation/consultation.facade';
import { initialShareRecommendationState, ShareRecommendationState } from './share-recommendation/share-recommendation.state';
import { ShareRecommendationEffects, ShareRecommendationFacade, shareRecommendationReducer } from './share-recommendation';
import { StateNamesEnum } from '@app/shared/utils/enums/state-names.enum';
import { AllowanceFacade } from '@app/pages/allowance/store/allowance.facade';
import { PetFacade } from './pet/pet.facade';

export interface AppState {
  router?: RouterReducerState;
  [StateNamesEnum.CORE_STATE_NAME]: CoreState;
  [StateNamesEnum.VET_STATE_NAME]: VetState;
  [StateNamesEnum.PATIENT_STATE_NAME]: PatientState;
  [StateNamesEnum.CONSULTATION_STATE_NAME]: ConsultationState;
  [StateNamesEnum.PRODUCTS_STATE_NAME]: ProductsState;
  [StateNamesEnum.PET_STATE_NAME]: PetState;
  [StateNamesEnum.SHARE_RECOMMENDATION_STATE_NAME]: ShareRecommendationState;
}

export const initialAppState: AppState = {
  [StateNamesEnum.CORE_STATE_NAME]: initialCoreState,
  [StateNamesEnum.VET_STATE_NAME]: initialVetState,
  [StateNamesEnum.PATIENT_STATE_NAME]: initialPatientState,
  [StateNamesEnum.CONSULTATION_STATE_NAME]: initialConsultationState,
  [StateNamesEnum.PRODUCTS_STATE_NAME]: initialProductsState,
  [StateNamesEnum.PET_STATE_NAME]: initialPetState,
  [StateNamesEnum.SHARE_RECOMMENDATION_STATE_NAME]: initialShareRecommendationState,
};

export function getInitialState(): AppState {
  return initialAppState;
}

export const appReducers: ActionReducerMap<AppState, any> = {
  router: routerReducer,
  [StateNamesEnum.CORE_STATE_NAME]: coreReducer,
  [StateNamesEnum.VET_STATE_NAME]: legacyVetReducer,
  [StateNamesEnum.PATIENT_STATE_NAME]: patientReducer,
  [StateNamesEnum.CONSULTATION_STATE_NAME]: consultationReducer,
  [StateNamesEnum.PRODUCTS_STATE_NAME]: productsReducer,
  [StateNamesEnum.PET_STATE_NAME]: petReducer,
  [StateNamesEnum.SHARE_RECOMMENDATION_STATE_NAME]: shareRecommendationReducer,
};

export const appEffects: any[] = [
  ...CoreEffects,
  ...VetEffects,
  ...PatientEffects,
  ...ConsultationEffects,
  ...ProductsEffects,
  ...PetEffects,
  ShareRecommendationEffects,
];

export const appFacades: any[] = [
  VetFacade,
  PatientFacade,
  ProductsFacade,
  ConsultationFacade,
  ShareRecommendationFacade,
  AllowanceFacade,
  PetFacade,
];

export const appServices: any[] = [];
